<template>
  <v-div class="grey">
    <br><br><br>
    <v-row class="grey mt-16">
      <v-row class="mt-n5">
        <v-col
          xl="1"
          md="1"
        />
        <v-col
          xl="6"
          md="3"
          class="ml-n3"
        >
          <p class="text-h3 mt-10">
            {{ $t("following") }}
          </p>
        </v-col>
        <v-col
          xl="1"
          md="7"
        />
      </v-row>

      <v-row class="mt-n5">
        <v-col
          xl="1"
          md="1"
        />
        <v-col
          xl="11"
          class="ml-n6"
        >
          <v-row
            no-gutters
            align="center"
            justify-md="start"
            justify="center"
            class=""
          >
            <template v-if="featuredLoading">
              <v-slide-item
                v-for="n in 20"
                :key="n"
              >
                <v-skeleton-loader
                  class="ma-2"
                  width="200"
                  type="image, list-item-two-line"
                />
              </v-slide-item>
            </template>
            <v-card
              v-for="(talent, index) in paginatedTalents"
              v-else
              :key="index"
              flat
              class="ma-3"
              rounded="lg"
            >
              <v-hover
                v-slot="{ hover }"
                :key="index"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                <!-- :src="allTalentAssetMapping[talent.value.profilePic]" -->
                <v-img
                  src="@/assets/images/default.png"
                  class="white--text align-end"
                  eager
                  width="213"
                  height="213"
                  lazy-src="@/assets/images/default.png"
                  alt="default"
                >
                  <v-card-text class="text--primary px-5">
                    <p class="ma-1 white--text font-weight-bold">
                      {{ talent.username }}
                    </p>
                    <p class="ma-1 pt-1 white--text">
                      {{ talent.firstname }}
                    </p>
                    <div
                      v-if="!hover"
                      class="d-flex"
                    >
                      <p
                        class="ma-1 white--text d-inline pl-1 pt-1 ml-0 font-weight-bold body-1"
                      >
                        $100
                      </p>
                    </div>

                    <v-scroll-y-reverse-transition leave-absolute>
                      <v-btn
                        v-if="hover"
                        transition="scroll-y-reverse-transition"
                        duration="40"
                        color="primary"
                        small
                        class="transition-ease-in-out white--text"
                        :to="`/talent/profile/${talent.firstname}`"
                      >
                        {{ $t("book_now") }}
                      </v-btn>
                    </v-scroll-y-reverse-transition>
                  </v-card-text>
                </v-img>
              </v-hover>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
  </v-div>
</template>
<script>
// graphql
import { GET_FOLLOWING_LIST } from "@/graphql/member";

export default {
  name: "Following",
  data() {
    return {
      // Variables added from old data
      showSnackbar: false,
      snackbarMessage: "",
      featuredLoading: false,
      talentLoading: false,
      allTalent: [],
      allFeaturedTalent: [],
      filteredTalent: [],
      availableGenres: [],
      selected: [],
      allTalentAssetMapping: {},
      featuredTalentAssetMapping: {},
      options: {
        duration: 500,
        easing: "easeInCubic",
      },
      paginatedTalents: [],
      paginatedFeaturedTalents: [],
      loading: false,
      isShowLoadMore: true,
    };
  },
  computed: {
    isMdAndAbove() {
      return [ "md", "lg", "xl" ].includes(this.$vuetify.breakpoint.name);
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    splideOptions() {
      if (this.isMdAndAbove) {
        return {
          rewind: true,
          perPage: 3,
          height: 550,
          gap: "1rem",
          fixedWidth: 380,
          fixedHeight: 550,
          cover: true,
        };
      } else {
        return {
          rewind: true,
          perPage: 1,
          height: 550,
          fixedHeight: 550,
          cover: true,
        };
      }
    },
  },
  mounted() {
    this.getFollowingList();
  },
  created() {
    this.userInfo = this.$store.getters['user']
    this.userInfo.accesstoken = this.$store.getters['jwt']
  },
  methods: {
    async getFollowingList() {
      const { data: { fetchFollowingList } } = await this.$apollo.query({
        query: GET_FOLLOWING_LIST,
        variables: {
          
          first: 15,
          skip: 1,
          email: this.userInfo.email,
          accesstoken: this.$store.getters['jwt'],
        },
        fetchPolicy: "network-only",
      });
      if (fetchFollowingList.success) {
        this.paginatedTalents = fetchFollowingList.userList
      } else {
        // Error
      }
    },
  },
};
</script>
<style lang="scss">
.talent-info {
  background-color:$dark_black;
}
</style>
